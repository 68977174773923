import * as React from 'react';

// markup
const NotFoundPage = () => {
  return (
    <div className="container-fluid container-fluid--custom mt-15 mb-15">
      <div className="row">
        <div className="col-12 text-center">
          <h1>Няма няма..</h1>
        </div>
      </div>
    </div>
  );
};

export const Head = () => {
  return (
    <div>
      <title>Some title</title>
      <meta name="description" content={'some description'} />
    </div>
  );
};

export default NotFoundPage;
